import React from 'react'
import { Card } from 'semantic-ui-react'
import TextTruncate from 'react-text-truncate'
import Img from 'gatsby-image'
import { withTranslation } from 'react-i18next'

import Share from '../Common/Share'
import { Link } from '../../locales'
import { unescape } from '../../utils/helpers'

const BlogCard = props => {
  const { i18n, blog } = props
  const { featured_media: featuredMedia, slug, author, acf, yoast, title, excerpt } = blog
  const url = `/blog/${slug}`

  return (
    <Card>
      <Link to={url} lang={i18n.language} aria-label={title}>
        {featuredMedia && featuredMedia.localFile && <Img fluid={{ ...featuredMedia.localFile.childImageSharp.fluid, aspectRatio: 1.5 }} alt={title} />}
      </Link>
      <Card.Content>
        <Card.Meta>{(acf && acf.blog_author) || author.name}</Card.Meta>
        <br />
        <Card.Header><Link to={url} lang={i18n.language} aria-label={title}>{unescape(title)}</Link></Card.Header>
        <Card.Description>
          <TextTruncate
            text={unescape(yoast ? yoast.metadesc : excerpt)}
            line={3}
            textTruncateChild={<span><Link to={url} lang={i18n.language} aria-label={title}><i className='icon red ta-arrow-1-right' aria-label={title} /></Link></span>} />
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <div className='right floated'>
          <Share url={`/${i18n.language}${url}`} />
        </div>
      </Card.Content>
    </Card>
  )
}

export default withTranslation()(BlogCard)
