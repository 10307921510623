import React from 'react'
import { Helmet } from 'react-helmet'
import {
  Container,
  Header,
  Card,
  Segment,
  Pagination,
  Icon,
  Responsive
} from 'semantic-ui-react'
import { withTranslation } from 'react-i18next'
import Img from 'gatsby-image'
import { graphql, navigate } from 'gatsby'

import { getFluidImage, getRawImage } from '../utils/images'
import { computerBreakpoint } from '../utils/constants'
import BlogCard from '../components/Blog/BlogCard'
import Layout from '../components/Layouts/PageLayout'

// Blog listing template
const Blog = props => {
  const {
    pageContext,
    i18n,
    data: { allFirestoreText },
    t
  } = props
  const blogs = pageContext.group
  const getActivePage = activePage => (activePage <= 1 ? '' : `/${activePage}`)
  const handlePaginationChange = (e, { activePage }) =>
    navigate(`/${i18n.language}/blog${getActivePage(activePage)}`)

  const textBlog = allFirestoreText.edges.find(
    ({ node: { lang } }) => lang === pageContext.additionalContext.lang
  )

  const pageNum = pageContext.index
  const title = `${t('blogs.header.title')} ${pageNum} ${t('htmlTitleSuffix')}`

  return (
    <div>
      <Helmet>
        <title>{title}</title>

        <meta name='description' content={textBlog && textBlog.node.content} />
        <meta
          name='image'
          content={getRawImage({ cloudinary: 'blog/Great_Wall_Jinshanling' })}
        />

        <meta property='og:title' content={t('blogs.header.title')} />
        <meta
          property='og:description'
          content={textBlog && textBlog.node.content}
        />
        <meta
          property='og:image'
          content={getRawImage({ cloudinary: 'blog/Great_Wall_Jinshanling' })}
        />

        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:creator' content='tourasia' />
        <meta name='twitter:title' content={t('blogs.header.title')} />
        <meta
          name='twitter:description'
          content={textBlog && textBlog.node.content}
        />
        <meta
          name='twitter:image'
          content={getRawImage({ cloudinary: 'blog/Great_Wall_Jinshanling' })}
        />
      </Helmet>

      <Layout pageContext={pageContext.additionalContext}>
        <Img
          fluid={getFluidImage({
            cloudinary: 'blog/Great_Wall_Jinshanling',
            ar: 2.5
          })}
        />
        <Segment padded='very' color='red' inverted>
          <Container>
            <Responsive maxWidth={computerBreakpoint - 1}>
              <Header as='h1' textAlign='center' inverted size='large'>
                {t('blogs.header.title')}
                <Header.Subheader>
                  {textBlog && textBlog.node.content}
                </Header.Subheader>
                <Header.Subheader>
                  <Icon className='ta-arrow-1-down' size='large' />
                </Header.Subheader>
              </Header>
            </Responsive>
            <Responsive minWidth={computerBreakpoint}>
              <Header as='h1' textAlign='center' inverted>
                {t('blogs.header.title')}
                <Header.Subheader>
                  {textBlog && textBlog.node.content}
                </Header.Subheader>
                <Header.Subheader>
                  <Icon className='ta-arrow-1-down' size='large' />
                </Header.Subheader>
              </Header>
            </Responsive>
          </Container>
        </Segment>

        <Segment vertical basic padded='very' className='lightgrey' inverted>
          <Container>
            <Card.Group itemsPerRow={3} stackable>
              {blogs
                .filter(({ node: { acf } }) => acf.language === i18n.language)
                .map(({ node }) => (
                  <BlogCard key={node.id} blog={node} />
                ))}
            </Card.Group>
            <Segment textAlign='center' padded='very' basic>
              <Pagination
                activePage={pageContext.index}
                firstItem={null}
                lastItem={null}
                pointing
                color='red'
                secondary
                prevItem={{
                  content: <i className='icon ta-arrow-2-left' />,
                  icon: true
                }}
                nextItem={{
                  content: <i className='icon ta-arrow-2-right' />,
                  icon: true
                }}
                totalPages={pageContext.pageCount}
                onPageChange={handlePaginationChange}
              />
            </Segment>
          </Container>
        </Segment>
      </Layout>
    </div>
  )
}

export default withTranslation()(Blog)

export const pageQuery = graphql`
  query BlogPage {
    allFirestoreText(filter: { section: { eq: "blog" } }) {
      edges {
        node {
          section
          content
          lang
        }
      }
    }
  }
`
